import React from "react";

import { css } from "@emotion/react";

import Typography from '../styles/typography';
import { breakpoints, gutters, Container, ContainerFluid } from "../styles/layouts";

export default ({ title, children, className }) => {

  return (
    <header css={HeaderStyle} className={className}>
      <Container>
        <h1 className="header-title">{ title }</h1>
        {children}
      </Container>
    </header>
  );
}

export const PageHeaderFluid = ({ title, children, className }) => {

  return (
    <header css={HeaderStyle} className={className}>
      <ContainerFluid>
        <h1 className="header-title">{ title }</h1>
        {children}
      </ContainerFluid>
    </header>
  );
}


const HeaderStyle = css`
  padding-top: calc(7rem + ${gutters.console.row});
  padding-bottom: ${gutters.console.row};

  background: #455A64;

  @media ${breakpoints.handheld} {
    padding-top: calc(5rem + ${gutters.handheld.row});
    padding-bottom: ${gutters.handheld.row};
  }

  .header-title {
    margin: 0;
    ${Typography.h1};
    color: #fff;
    text-align: left;
  }
`;
