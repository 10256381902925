import React from "react";
import PropTypes from "prop-types";
import { Global, css } from "@emotion/react";

import Header from "../components/header";
import Footer from "../components/footer";
import PageHeader from "../components/page-header";
import Article from "../components/article";

import { GlobalStyles } from "./global-styles";
import { Section, Container, LayoutStyle } from "../styles/layouts";


const Layout = (props) => {

  const title = props.pageContext.title || props.pageContext.frontmatter.title;

  return (
    <div css={LayoutStyle} data-scrolllock={props.isscrolllock}>
      <Global styles={GlobalStyles} />
      <Header />
      <main>
        <article css={articleStyle}>
          <PageHeader title={title} />
          <Section>
            <Container>
              <Article>
                {props.children}
              </Article>
            </Container>
          </Section>
        </article>
      </main>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout;

const articleStyle = css`
  position: relative;
`;
